* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body {
  background: url('../assets/PortfolioBackground.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-align: center;
  align-items: center;
  height: 100vh;
}
.App {
  display: grid;
  grid-template-columns: '10fr';
  grid-template-rows: '1fr 4fr 2fr';
  justify-items: center;
  width: 100vw;
  height: 100vh;
}
/* Default Page Layout */
.menubar {
  display: flex;
  justify-content: space-between;
  background-color: rgba(157, 157, 157, 0.2);
  backdrop-filter: blur(5px);
 
  height: 4vh;
  width: 100%;
  padding: 0 5px;
}
.main {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.dock {
  height: 15vh;
  width: fit-content;

  display: flex;
  justify-content: center;

  background-color: rgba(190, 190, 190, 0.3 );
  border-radius: 1rem;
  border: 1px rgba(245, 245, 245, 0.524) ridge;
  backdrop-filter: blur(3px);
}

/* MENU BAR */
.filemenu {
  margin: 0 10px 0 10px;
  color: white;
  font-weight: bold;
  font-size: 18px
}
.menuitems {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: fit-content;
}
/* Used for menubar and Window menu bar */
.menuwidgets {  
  display: flex;
  justify-content: left;
  align-items: center;
}

/* Dock */
.dockcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px auto;
  padding: 0px 20px;
  z-index: 0;
}

/* Home Main */
.window {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  background-color: #EDEEEF;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 0;
}
.window:hover {
  z-index:2;
}
.windowmenubar {
  display: grid;
  grid-template-columns: 0.15fr 1fr 0.15fr;
  grid-template-rows: 30px;
  background-color: #f2f3f5;
  border-bottom: 1px #C9C9CB solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.windowdots {
  width: 11px;
  height: 11px;
  
  margin: 4px;
  border-radius: 50%;
 }
 .red {
  background-color: #EC6A5E;
  border: 1px #D65549 solid;
 }
 .orange {
  background-color: #F3BE4F;
  border: 1px #D69B00 solid;
 }
 .green {
  background-color: #61C554;
  border: 1px #59a64d solid;
 }
 .windowcontent {
  width: 100%;
 }

 .tabcontainer {
  border: 1px solid #D6D6D6;
  position:relative;
  margin: 4px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
 }
 .tabcontainer:hover {
  background-color: #e8e8e8;
 }

 .tab {
  border-right: 1px solid #D6D6D6;
  width: fit-content;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
 }

 .tab:target-within {
  background-color: black;
 }

 .windowbartitle {
  color: #525252;
  font-weight: bold;
  text-align: center;
  margin: 2px auto;
 }

 .overviewwindow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5% 0px 5% 0px;
  width: initial;
 }

 .section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 50%;
 }
 
 .section2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-right: 10%;
  text-align: left;
  overflow: scroll;
  font-weight: 300;
  width: 60%;
 }

 .borderbackground {
  border-radius: 50%;
  border-image: linear-gradient(red, blue) 27 / 35px;
 }
 .overviewlinks {
  margin-top: 7%;
  display: flex;
  flex-direction: row;
  font-size: 80%;
  justify-content: flex-start;
 }
 .overviewbutton {
  width: fit-content;
  height: 20px;

  margin-right: 10px;
  margin-left: 0px;
  padding: 0px 11px;

  background-color: white;
  box-shadow: rgba(145, 145, 145, 0.8) 0px 1px 1px 0px;
  border:#d8d8d8 solid .5px; 
  border-radius: 6px;
  font-size: 90%;
  font-weight: 400;
 }

 .extrawindowbaroptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 18px;
  font-size: 100%;
 }

/* Projects Page */
.projects-setup{
  width: 100%;
  height: 84vh;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: auto auto;
  backdrop-filter: blur(5px);
  z-index: 0;
}
.projectapp {
  width: 17vh;
  height: 20vh;
  z-index: 1000;
  margin-left:auto;
  margin-right: auto;
  margin-top: 10%
}

.blur {
  width: 100%;
  height: 16vh;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
}

.projectapp img {
  box-shadow: rgba(6, 24, 44, 0.65) 0px 2px 2px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 20%;
}

/* Project Info Component */
.projectinfo {
  text-align: left;
  margin: 5px;
  font-size: 14px;
}
.techusedlist {
  display: flex;
  font-size: 14px;
  height: 50%;
}
.projectinfosectiontitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom:0;
}

.resumeiframecontainer {
  height: 100vh;
}

.skills-container {
  padding: 10px;
  font-weight: 300;
  font-size: 75%;
}

.skillscontent {
  font-weight: 500;
}

.align-left {
  text-align: left;
  align-content: center;
}

.skills-container p {
  margin: 5px;
}

.bi-download:hover {
  color: rgb(71, 181, 44) !important;
  font-size: 20px !important;
}

/* PageLink Component*/
.pagelink {
  margin: 1px 8px;
  aspect-ratio: 1 / 1;
  cursor: pointer;
}
img.pagelink {
  height: 50%;
  width: 10vh;
}
.pagelinktitle {
  color: white;
  margin-bottom: 0;
}
.docksection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  align-items: center;
}
.docksection:hover {
  transform: translateY(-5px);
}

.techskills {
  padding: 2% 2% 2% 2%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  align-items: baseline;
}

.skillitem {
  margin-bottom: 10%;
  padding: 5%;
}
.techskillsnames {
  font-size: 14px;
  font-weight: 300;
}

.slownote {
  font-size: 14px;
  font-weight: 300;
}