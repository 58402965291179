.container {
  background: inherit;
  display: inline-block;
  border: 5px solid inherit;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 255px);
  grid-template-rows: repeat(3, 225px);
  grid-gap: 5px;
}
.cell {
   /* center the cell content */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  font-size: 3rem;
  font-weight: bold;
  background: white;
}

/* Cell 1 */
.dropdown {
  width: 100px;
  font-size: 2vh;
}

div > h1 {
  font-size: 20px;
  font-weight: bold;
}
